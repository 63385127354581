import { getAllPricesbyTracker } from "../../api/subscriptions.api"

const state = {
    subscriptions_loading: false,
    subscriptions: [],
    selectedSubscription: null,
    selectedOptions: [],
    loading: true,
}

const getters = {
    getSubscriptions(state) {
        return state.subscriptions;
    },
    isSubscriptionsLoading(state) {
        return state.subscriptions_loading;
    },
    getSelectedSubscription(state) {
        return state.selectedSubscription;
    },
    getSelectedOptions(state) {
        return state.selectedOptions;
    },
    getLoading(state) {
        return state.loading;
    }
}

const actions = {
    async fetchSubscriptions({ commit, state  }) {
        if (state.subscriptions_loading){
            return;
        }

        try {
            commit('SET_SUBSCRIPTIONS_LOADING', true);
            const response = await getAllPricesbyTracker();
            commit('SET_SUBSCRIPTIONS', response.data);
            commit('SET_SELECTEDSUBSCRIPTION', response.data[0]);
            commit('SET_LOADING', false);
            console.log("response.data", response.data);
        } catch (error) {
            console.log(error);
            commit('SET_LOADING', false);
        } finally {
            commit('SET_SUBSCRIPTIONS_LOADING', false);
        }
    },
    async setSelectedSubscription({ commit }, data) {
        commit('SET_SELECTEDSUBSCRIPTION', data);
    },
    async addOptionToSelectedOptions({ commit }, elementToAdd) {
       commit('ADD_SUBSCRIPTION_OPTION', elementToAdd);
    },
    async removeOptionToSelectedOptions({ commit }, elementToRemove) {
        commit('REMOVE_SUBSCRIPTION_OPTION', elementToRemove);
    }
}

const mutations = {
    SET_SUBSCRIPTIONS(state, data) {
        state.subscriptions = data;
    },
    REMOVE_SUBSCRIPTIONS(state, data) {
        state.subscriptions = [];
    },
    SET_SUBSCRIPTIONS_LOADING(state, data) {
        state.subscriptions_loading = data;
    },
    SET_SELECTEDSUBSCRIPTION(state, data) {
        state.selectedSubscription = data;
    },
    REMOVE_SELECTEDSUBSCRIPTION(state) {
        state.selectedSubscription = null;
    },
    ADD_SUBSCRIPTION_OPTION(state, elementToAdd) {
        console.log("elementToAdd", elementToAdd);
         if (!state.selectedOptions.includes(elementToAdd)) {
            state.selectedOptions.push(elementToAdd);
        }
    },
    REMOVE_SUBSCRIPTION_OPTION(state, elementToRemove) {
        let idx = state.selectedOptions.indexOf(elementToRemove);
        if (idx > -1) {
            state.selectedOptions.splice(idx, 1);
        }
    },
    SET_LOADING(state, data) {
        state.loading = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
    <div class="subscription-detail">
    {{$t('Subscription_type') }}: {{ subscriptionDetail }}
    </div>
</template>

<script>
export default {
    name: "SubscriptionCurrentType",
    props: {
        tracker: null,
        current: null,
    },

    computed: {
      subscriptionDetail() {
        if (!this.current) {
          return "...";
        }
        //console.log("current", this.current);
        let subscription = null;
        if (this.current.order_interval_unit) {
          subscription = this.$i18n.tc(this.current.order_interval_unit + 'Key', this.current.order_interval_frequency)
        } else if (this.current.interval) {
          if (this.current.interval == 'year') {
            subscription = this.$i18n.tc('recurringYearKey', this.current.interval_count)
          } else {
            subscription = this.$i18n.tc('recurringMonthKey', this.current.interval_count)
          }
          if (this.current.status && this.current.status != 'active') {
            subscription = subscription + ' (' + this.$i18n.t(this.current.status) + ')'
          } else if (this.current.cancel_at_period_end) {
            subscription = subscription + ' (' + this.$i18n.t('cancel') + ': ' + this.$i18n.t('period_end') + ')'
          } else if (this.current.cancel_at) {
            subscription = subscription + ' (' + this.$i18n.t('cancel') + ': ' + this.current.cancel_at + ')'
          }
        }
        return subscription
      }
    }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

</style>
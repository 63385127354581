<template>
    <div class="sidebar-sticky" id="trackers_list">
        <ul class="nav flex-column"> 
            <li class="nav-item border-bottom" style="border-color: #333 !important;cursor:default;">
                <div v-if="getDevices.length==0">{{$t('loading')}}...</div>
                <div v-else class="nav-link disabled" disabled>{{$t('all_trackers')}} ({{getDevices.length}})</div>
            </li>

            <li v-for="tracker in getDevices" :key="tracker.serial" class="nav-item">
                <div class="nav-link d-flex" v-on:click="setSelectedDevice(tracker)"
                     v-bind:class="{'active': (getSelectedDevice&&getSelectedDevice.id==tracker.id) }">

                    <StateIndicator :state="isSubscriptionEnded ? 'offline' : 'online'" />
                    <span class="pl-2"> {{ tracker.name }} </span>

                </div>
            </li>
        </ul>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import StateIndicator from '@/components/ui/StateIndicator'

    import "@/assets/scss/style.scss"

    import { mapActions, mapGetters, mapMutations } from "vuex";

    let moment = require('moment');

    export default {
        name: 'DevicesListSideBar',
        components: {
            StateIndicator,
        },

        computed: {
            ...mapGetters('devices', ['getDevices', 'getSelectedDevice']),
            ...mapGetters('subscriptions', ['getSubscriptions', 'isSubscriptionsLoading']),
            isSubscriptionEnded: function () {
                return this.getSelectedDevice && this.getSelectedDevice.tracker_status.sub_end_date && (new Date(this.getSelectedDevice.tracker_status.sub_end_date) < new Date());
            },
            
        },

        methods: {
            ...mapActions('devices', ['fetchDevices', 'setSelectedDevice']),
            ...mapActions('subscriptions', ['fetchSubscriptions']),
            ...mapActions('devices', ['removeDevices']),
            ...mapActions('devices', ['removeSelectedDevice']),
        },

        async created() {
            let parameters = this.$route.query;
            await this.removeDevices();
            await this.removeSelectedDevice();
            await this.fetchDevices();
            if ( parameters['serial'] ) {
                await this.setSelectedDevice(this.getDevices.filter( device => device.serial == parameters['serial'])[0]);
            }
            if ( this.getSelectedDevice === null && this.getDevices.length != 0 ) {
              await this.setSelectedDevice(this.getDevices[0]);
            }
          if (!this.getSubscriptions.length && !this.isSubscriptionsLoading) {
            await this.fetchSubscriptions();
          }
        },
    }

</script>